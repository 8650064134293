import * as d3 from "d3";

import "./modules/person-card";

import "./tools/burger";
import "./tools/scroll-to-top";

import "./modules/intro";
import "./modules/accordion";
import "./modules/legend";
import "./modules/about";
import "./modules/header";

window.d3 = d3;
