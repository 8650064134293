export const POSITIONS = {
    1: "Руководитель фракции",
    3: "Заместитель руководителя фракции",
    5: "Первый замруководителя фракции",
    6: "Председатель комитета",
    7: "Первый зампредседателя комитета",
    8: "Заместитель председателя комитета",
    9: "Член комитета",
    10: "Председатель ГД и его первые заместители",
    12: "Заместитель председателя Госдумы",
};